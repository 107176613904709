import React from "react";
import { useEffect } from "react";
import img1 from "../../Data/concurso21julio/BASES 20072024_page-0001.jpg";
import img2 from "../../Data/concurso21julio/BASES 20072024_page-0002.jpg";
import img3 from "../../Data/concurso21julio/BASES 20072024_page-0003.jpg";
import img4 from "../../Data/concurso21julio/BASES 20072024_page-0004.jpg";
import img5 from "../../Data/concurso21julio/BASES 20072024_page-0005.jpg";
import img6 from "../../Data/concurso21julio/BASES 20072024_page-0006.jpg";
import img7 from "../../Data/concurso21julio/BASES 20072024_page-0007.jpg";
import img8 from "../../Data/concurso21julio/BASES 20072024_page-0008.jpg";
import img9 from "../../Data/concurso21julio/BASES 20072024_page-0009.jpg";
import img10 from "../../Data/concurso21julio/BASES 20072024_page-0010.jpg";

function Novedades() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content-novedades">
      <div className="img-concurso">
        <img src={img1} alt="" />
        <img src={img2} alt="" />
        <img src={img3} alt="" />
        <img src={img4} alt="" />
        <img src={img5} alt="" />
        <img src={img6} alt="" />
        <img src={img7} alt="" />
        <a href="https://forms.gle/e9dfTL6A2YcU24Hs7"> >>>LINK AL FORMULARIO ACA </a>
        <img src={img8} alt="" />
        <a href="https://forms.gle/e9dfTL6A2YcU24Hs7"> >>>LINK AL FORMULARIO ACA </a>
        <img src={img9} alt="" />
        <a href="https://forms.gle/e9dfTL6A2YcU24Hs7"> >>>LINK AL FORMULARIO ACA </a>
        <img src={img10} alt="" />
        <a href="https://forms.gle/e9dfTL6A2YcU24Hs7"> >>>LINK AL FORMULARIO ACA </a>
      </div>

      <div className="book">
        <div className="gap"></div>
        <div className="pages">
          <div className="page"></div>
          <div className="page"></div>
          <div className="page"></div>
          <div className="page"></div>
          <div className="page"></div>
          <div className="page"></div>
        </div>
        <div className="flips">
          <div className="flip flip1">
            <div className="flip flip2">
              <div className="flip flip3">
                <div className="flip flip4">
                  <div className="flip flip5">
                    <div className="flip flip6">
                      <div className="flip flip7"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Novedades;
